<template>
  <UiScrollWrapper>
    <ul
      class="flex"
      :class="[
        {
          'pointer-events-none opacity-30 grayscale': props.disabled,
        },
      ]"
    >
      <li
        v-for="tab of props.tabs"
        :key="tab.id"
        :aria-selected="props.active === tab.id"
        role="tab"
        class="-mb-0.5 flex size-full cursor-pointer items-center justify-center whitespace-nowrap border-b-2 border-blue-200 p-2 text-primary transition-all"
        :class="[
          currentVariantStyle.li.base,
          tab.class || '',
          {
            [currentVariantStyle.li.active]: props.active === tab.id,
            'pointer-events-none opacity-30 grayscale': tab.disabled,
            'font-bold': props.active === tab.id,
          },
        ]"
        @click.prevent="hanleTabClick(tab)"
      >
        <slot
          :name="tab.id"
          v-bind="{ tab, active: props.active }"
        >
          <NuxtLink
            v-if="tab?.link ?? ''"
            v-slot="{ href }"
            :to="tab?.link"
            custom
            no-prefetch
          >
            <a
              :href="href"
              @click.prevent
            >{{ tab.title }}</a>
          </NuxtLink>

          <template v-else>
            {{ tab.title }}
          </template>
        </slot>
      </li>
    </ul>
  </UiScrollWrapper>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import UiScrollWrapper from '../helpers/UiScrollWrapper/UiScrollWrapper.vue'
import type { TabId, UiTabItem } from './UiTabs.types'

const props = withDefaults(defineProps<UiTabs>(), {
  disabled: false,
  variant: 'default',
})
const emit = defineEmits<{
  (e: 'update:active', value: TabId): void
}>()

const TabVariantStyle = {
  default: {
    li: {
      base: '',
      active: 'border-b-4 border-b-blue',
    },
  },
  menu: {
    li: {
      base: 'py-4',
      active: 'border-b-8 border-b-notification-info',
    },
  },
} as const
type UiTabVariant = keyof typeof TabVariantStyle

const router = useRouter()

const currentVariantStyle = computed(() => TabVariantStyle[props.variant])

interface UiTabs {
  tabs: UiTabItem[]
  active: TabId
  disabled?: boolean
  variant?: UiTabVariant
}

async function hanleTabClick(tabItem: UiTabItem) {
  if (!tabItem?.id) {
    return
  }

  if (tabItem?.action && typeof tabItem.action === 'function') {
    tabItem.action()
  }

  if (typeof tabItem?.link === 'string') {
    await router.push(tabItem.link)
  }

  emit('update:active', tabItem.id)
}
</script>
